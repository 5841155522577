<template>
  <div class="recapthca-policy">
    This site is protected by reCAPTCHA and the Google
    <a
      href="https://policies.google.com/privacy"
      target="_blank"
      rel="noreferrer noopener"
    >
      Privacy Policy
    </a>
    and
    <a
      href="https://policies.google.com/terms"
      target="_blank"
      rel="noreferrer noopener"
    >
      Terms of Service
    </a>
    apply.
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.recapthca-policy {
  font-size: 0.7rem;
}
</style>