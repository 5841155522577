<template>
  <auth-layout :background-image="imgUrl">
    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
      {{ $t("auth.login.welcome") }}
    </b-card-title>
    <b-card-text class="mb-2">
      {{ $t("auth.login.call-to-action") }}
    </b-card-text>

    <!-- form -->
    <validation-observer ref="loginValidation">
      <b-form class="auth-login-form mt-2" @submit.prevent>
        <!-- email -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="login-email">{{ $t("fields.email") }}</label>
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t('fields.email')"
            rules="required|email"
          >
            <b-form-input
              id="login-email"
              v-model="form.email"
              :state="errors.length > 0 ? false : null"
              name="email"
              type="email"
              autocomplete="email"
              placeholder="example@facerecognition.io"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- forgot password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="login-password">{{ $t("fields.password") }}</label>
            <router-link :to="{ name: 'forgot-password' }">
              <small>{{ $t("auth.login.redirect-forgot-password") }}</small>
            </router-link>
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t('fields.password')"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="login-password"
                v-model="form.password"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- checkbox -->
        <b-form-group>
          <b-form-checkbox
            id="remember-me"
            v-model="form.rememberMe"
            name="checkbox-1"
          >
            {{ $t("auth.login.remember-me") }}
          </b-form-checkbox>
        </b-form-group>

        <!-- submit buttons -->

        <b-button
          type="submit"
          variant="primary"
          block
          :disabled="loading"
          @click="validateForm"
        >
          <template v-if="loading">
            <b-spinner small />
            <span class="sr-only"> {{ $t("general.loading") }}</span>
          </template>
          <span v-if="!loading"> {{ $t("auth.login.action") }} </span>
        </b-button>
      </b-form>
    </validation-observer>
    <p class="text-center mt-2">
      <span>{{ $t("auth.login.redirect-sign-up-message") }}</span>
      <router-link :to="{ name: 'register' }">
        <span>&nbsp;{{ $t("auth.login.redirect-sign-up-link") }}</span>
      </router-link>
    </p>
    <recaptcha-policy />
  </auth-layout>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BrandLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AuthLayout from "./components/AuthLayout.vue";
import RecaptchaPolicy from "@/components/ReCaptchaPolicy.vue";

export default {
  components: {
    BrandLogo,
    ValidationProvider,
    ValidationObserver,
    AuthLayout,
    RecaptchaPolicy,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      loading: false,
      form: {
        recaptcha: "",
        email: "",
        password: "",
        rememberMe: false,
      },
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login/background.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    async verify() {
      if (true || process.env.NODE_ENV == "production") {
        await this.$recaptchaLoaded();
        this.form.recaptcha = await this.$recaptcha("login");
      }
    },
    validateForm() {
      this.$refs.loginValidation.validate().then(async (success) => {
        if (!success) return;
        this.loading = true;
        await this.verify();

        this.$auth
          .login({
            data: this.form,
            staySignedIn: this.form.rememberMe,
          })
          .then(
            ({ data }) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Welcome ${data.userData.name}`,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });

              this.$auth.fetch().then(() => {
                try {
                  const redirect = this.$auth.redirect();
                  this.$router.push(
                    redirect &&
                      redirect.type === 401 &&
                      redirect.from.fullPath != redirect.to.fullPath
                      ? { path: redirect.from.fullPath }
                      : {
                          name: "admin-dashboard",
                        }
                  );
                } catch (e) {}
              });
            },
            ({
              response: {
                data: { title },
              },
            }) => {
              this.$toast({
                component: ToastificationContent,
                props: { title, icon: "XIcon", variant: "danger" },
              });
            }
          )
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
